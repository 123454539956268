<template>
  <div>
    <div class="row animated-container">
      <div class="col-12 col-md-6 mt-md-5">
        <img src="@/assets/images/whoopsie-image-grey.png" class="w-100">
      </div>
      <div class="col-12 col-md-6 mt-md-5 align-self-center">
        <h1 class="mt-4">{{ $t('whoops') }}<br />{{ $t('notValidLinkText') }}</h1>
        <div class="mt-3">
          {{ $t('mostLikelyText') }}:
        </div>
        <ul class="dash">
          <li>{{ $t('theCampaignExpired') }}</li>
          <li>{{ $t('linkAlreadyUsedText') }}</li>
          <li>{{ $t('linkAlreadyDeactivated') }}</li>
          <li>{{ $t('campaignCancelledText') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InvalidToken',
}

</script>
