<template>
  <div class="container-fluid fixed-top nav-bottom-border p-0" :style="topnavStyles" >
    <nav class="navbar container navbar-light navbar-expand-md px-md-3">
      <div class="pl-0 pr-0 col-4">
        <div v-if="showCount" class="font-weight-bold">
          <span v-if="limitType === 1">{{ $t('orderSelect', {selected: selectedCount, allowed: maxCount}) }}</span>
          <div v-else-if="limitType === 2" class="font-weight-bold text-left">
            {{ $t('orderBudgetRemaining') }}:<br class="d-md-none d-block" /> {{orderBudgetRemaining.toFixed(2)}} {{currency}}
          </div>
          <span v-else-if="limitType === 3">{{selectedCount}} {{ $t('selected') }}</span>
        </div>
      </div>
      <div class="pl-3 d-flex justify-content-center col-4">
        <div v-if="!storeLogo">
            <img height="30" src="@/assets/images/framme_logo_black.png"/>
        </div>
        <div v-else>
          <img height="30" :src="storeLogo" />
        </div>
      </div>
      <div class="pr-0 col-4">
        <div v-if="showCount && (requirePayment || limitType === 2)" class="font-weight-bold text-right">
          {{ $t('orderTotal') }}:<br class="d-md-none d-block" /> {{totalPrice.toFixed(2)}} {{currency}}
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'CustomerTopNav',
  components: {
  },
  props: {
    storeLogo: String,
    showCount: Boolean,
    maxCount: Number,
    selectedCount: Number,
    requirePayment: Boolean,
    totalPrice: Number,
    orderBudget: Number,
    orderBudgetRemaining: Number,
    currency: String,
    limitType: Number,
    campaignTemplate: Object
  },
  data() {
    return {
    }
  },
  computed: {
    topnavStyles() {
      let values = {}
      if (this.$store.getters.customerCampaignTemplate) {
        values['background'] = this.$store.getters.customerCampaignTemplate.header_background;
        values['color'] = this.$store.getters.customerCampaignTemplate.header_font_color;
      } else if (this.campaignTemplate) {
        values['background'] = this.campaignTemplate.header_background
        values['color'] = this.campaignTemplate.header_font_color
      }
      return values;
    },
  }
}

</script>
