<template>
  <div v-if="!tokenChecked">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="tokenIsInvalid">
    <div class="container">
      <InvalidToken />
    </div>
  </div>
  <div v-else class="vh-100" :style="bodyStyles">
    <CustomerTopNav
        v-bind:storeLogo=data.store_logo
        v-bind:showCount=false
        v-bind:maxCount=0
        v-bind:selectedCount=0
        v-bind:requirePayment=data.require_payment
        v-bind:totalPrice=0
        v-bind:currency=data.currency
        v-bind:campaignTemplate=data.template
    />
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4 mb-4 animated-container">
      <div class="row" :style="containerStyles">
        <div class="col-12 col-md-6">
          <img :src="data.picture_url" class="w-100 py-3">
        </div>
        <div class="col-12 col-md-6 align-self-center">
          <h1 :style="headerStyles">{{ data.thanks_header }}</h1>
          <div v-html="formattedText"></div>
          <div class="mt-3">{{ data.thanks_signature }}</div>
          <div v-if="actionButtons" class="row mt-3">
            <div v-if="actionButtons.primary" class="col-md-6">
              <button class="btn btn-lg btn-block mt-2 button-hover" :style="primaryActionButtonStyles" v-on:click="openInNewTab(actionButtons.primary.url)">
                {{ actionButtons.primary.text }}
              </button>
            </div>
            <div v-if="actionButtons.secondary" class="col-md-6">
              <button class="btn btn-lg btn-block mt-2 button-hover" :style="secondaryActionButtonStyles" v-on:click="openInNewTab(actionButtons.secondary.url)">
                {{ actionButtons.secondary.text }}
              </button>
            </div>
          </div>
          <div v-if="!data.is_multi_invitation" class="mt-3">
            {{ $t('thankYouPssst') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerTopNav from '../components/CustomerTopNav.vue'
import InvalidToken from '../components/InvalidToken.vue'

export default {
  name: 'CustomerCampaignThanks',
  components: {
    CustomerTopNav,
    InvalidToken
  },
  data() {
    return {
      tokenChecked: false,
      tokenIsInvalid: false,
      updating: false,
      data: {},
      token: null,
    }
  },
  created: function () {
    this.getCampaignWithToken()
  },
  computed:{
    formattedText(){
      return this.data.thanks_text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    },
    headerStyles() {
      let styles = {}
      if(this.data.template){
        if(this.data.template.font_color_title) {
          styles['color'] = this.data.template.font_color_title;
        }
      }
      return styles;
    },
    bodyStyles() {
      let styles = {}
      if(this.data.template){
        if(this.data.template.background_color) {
          styles['background-color'] = this.data.template.background_color;
        }
      }
      return styles
    },
    containerStyles() {
      let styles = {}
      if(this.data.template){
        if(this.data.template.container_bg_color) {
          styles['background-color'] = this.data.template.container_bg_color;
        }
        if(this.data.template.font_color_body) {
          styles['color'] = this.data.template.font_color_body
        }
      }
      return styles
    },
    actionButtons() {
      const { primary_action_text, primary_action_url, secondary_action_text, secondary_action_url } = this.data
      const buttons = {}

      if (primary_action_text && primary_action_url) {
        buttons.primary = {
          text: primary_action_text,
          url: primary_action_url
        }
      }

      if (secondary_action_text && secondary_action_url) {
        buttons.secondary = {
          text: secondary_action_text,
          url: secondary_action_url
        }
      }

      return Object.keys(buttons).length > 0 ? buttons : null
    },
    primaryActionButtonStyles() {
      const styles = {
        'background-color': '#222222',
        'color': '#FFFFFF',
        'border-color': '#222222',
        'border-radius': '3px'
      };
      const { template } = this.data;

      if (template) {
        if (template.button_primary_bg_color) {
          styles['background-color'] = template.button_primary_bg_color
        }
        if (template.button_primary_font_color) {
          styles['color'] = template.button_primary_font_color
        }
        if (template.button_primary_border_color) {
          styles['border-color'] = template.button_primary_border_color
        }
        if (template.button_border_radius) {
          styles['border-radius'] = template.button_border_radius
        }
      }

      return styles;
    },
    secondaryActionButtonStyles() {
      const styles = {
        'background-color': '#D9D9D7',
        'color': '#222222',
        'border-color': '#D9D9D7',
        'border-radius': '3px'
      };
      const { template } = this.data

      if (template) {
        if (template.button_secondary_bg_color) {
          styles['background-color'] = template.button_secondary_bg_color
        }
        if (template.button_secondary_font_color) {
          styles['color'] = template.button_secondary_font_color
        }
        if (template.button_secondary_border_color) {
          styles['border-color'] = template.button_secondary_border_color
        }
        if (template.button_border_radius) {
          styles['border-radius'] = template.button_border_radius
        }
      }

      return styles
    }
  },
  methods: {
    getCampaignWithToken() {
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/campaigns/open/'+ this.$route.params.token + '/verify-payment/';
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
      .then(function (response) {
        if(response.data.language){
          vm.$i18n.locale = response.data.language;
        }
        vm.data = response.data;
        vm.tokenChecked = true;
      })
      .catch(function () {
        vm.tokenIsInvalid = true;
        vm.tokenChecked = true;
        vm.updating = false;
      });
    },
    openInNewTab(url) {
      window.open(url, '_blank');
    },
  }
}
</script>
